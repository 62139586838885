<template>
  <div class="create-admin">
    <p class="text-header">
      {{ $route.params.id == 0 ? "Create Role" : "Manage Role" }}
    </p>

    <div class="header-title">Role info</div>
    <div class="body">
      <b-row>
        <b-col cols="6">
          <InputText
            v-model="form.name"
            textFloat="Role Name"
            placeholder="Role Name"
            type="text"
            name="textRolename"
            isRequired
            :isValidate="$v.form.name.$error"
            :v="$v.form.name"
          />
        </b-col>
        <b-col cols="6"></b-col>
      </b-row>
    </div>
    <div class="">
      <div class="my-3"></div>
      <div class="header-title">Access Permission</div>
      <div class="body">
        <b-row>
          <b-col cols="6">
            <InputSelect
              title="Access Type"
              name="type"
              isRequired
              class="mt-2"
              v-model="form.adminRoleAccessTypeId"
              v-bind:options="optionAccessType"
              valueField="id"
              textField="name"
              :isValidate="$v.form.adminRoleAccessTypeId.$error"
              :v="$v.form.adminRoleAccessTypeId"
            >
              <template v-slot:option-first>
                <b-form-select-option :value="null" disabled
                  >-- Select AccessType --</b-form-select-option
                >
              </template>
            </InputSelect>
          </b-col>
        </b-row>
        <b-row
          class="tw-mt-2"
          v-if="
            form.adminRoleAccessTypeId === 2 || form.adminRoleAccessTypeId === 4
          "
        >
          <b-col cols="6">
            <InputTag
              :options="optionBrand"
              label="Brand"
              :vmodel="form.listBrandId"
              choseOne
              isRequired
              :isValidate="$v.form.listBrandId.$error"
              :v="$v.form.listBrandId"
              @onSearch="handleSearchOptionBrand"
              useServerOption
            />
          </b-col>
        </b-row>
        <b-row
          class="tw-mt-2"
          v-if="
            form.adminRoleAccessTypeId === 3 || form.adminRoleAccessTypeId === 4
          "
        >
          <b-col cols="6">
            <InputTag
              :options="optionVenue"
              label="Branch"
              :vmodel="form.listBranchId"
              choseOne
              isRequired
              :isValidate="$v.form.listBranchId.$error"
              :v="$v.form.listBranchId"
              @onSearch="handleSearchOptionVenue"
              useServerOption
            />
          </b-col>
        </b-row>
      </div>
    </div>
    <div class="my-3"></div>
    <div class="header-title">Menu condition</div>
    <div class="body">
      <b-form-group label="" v-slot="{ ariaDescribedby }">
        <b-form-checkbox-group
          v-model="form.permissionId"
          id="checkbox-admin"
          :options="optionPermissionGroup"
          :aria-describedby="ariaDescribedby"
          name="flavour-1"
          @change="onChangePermission"
          stacked
        ></b-form-checkbox-group>
      </b-form-group>

      <p class="m-0 text-error" v-if="$v.form.permissionId.$error">
        Please select role
      </p>
    </div>

    <div class="footer">
      <b-button class="btn-filter btn cancel" @click="goBackRouter">
        Cancel
      </b-button>
      <b-button
        class="btn-filter btn submit"
        :disabled="isLoading"
        @click="manageAdminRole"
      >
        <div class="d-flex align-items-center justify-content-center">
          {{ $route.params.id == 0 ? "Create" : "Save" }}

          <b-spinner
            label="Spinning"
            class="ml-1"
            small
            v-if="isLoading"
          ></b-spinner>
        </div>
      </b-button>
    </div>

    <ModalAlertText :textModal="textModal" :arrModal="arrStatus" />
  </div>
</template>

<script>
import InputTag from "@/components/inputs/InputTag.vue";
import { required, requiredIf } from "vuelidate/lib/validators";

import ModalAlertText from "@/components/modal/ModalAlertText.vue";
export default {
  components: {
    ModalAlertText,
    InputTag,
  },
  data() {
    return {
      // state
      form: {
        name: "",
        permissionId: [],
        adminRoleAccessTypeId: null,
        listBrandId: [],
        listBranchId: [],
      },

      //state backup
      listBrandIdBackup: [],
      listBranchIdBackup: [],

      optionsPremission: [],

      // state loading
      isLoading: false,
      isLoadingForm: false,

      // state modal
      textModal: "",
      arrStatus: [],

      //access permission
      optionAccessType: [],
      optionBrand: [],
      optionVenue: [],

      timer: null,
    };
  },
  validations() {
    return {
      form: {
        name: { required },
        permissionId: { required },
        adminRoleAccessTypeId: {
          required: requiredIf(() => {
            return this.$isEventGroup;
          }),
        },
        listBrandId: {
          required: requiredIf(() => {
            return (
              this.$isEventGroup &&
              (this.form.adminRoleAccessTypeId === 2 ||
                this.form.adminRoleAccessTypeId === 4)
            );
          }),
        },
        listBranchId: {
          required: requiredIf(() => {
            return (
              this.form.adminRoleAccessTypeId === 3 ||
              this.form.adminRoleAccessTypeId === 4
            );
          }),
        },
      },
    };
  },
  watch: {
    "form.adminRoleAccessTypeId"(val) {
      if (val) {
        this.form.listBranchId = this.listBranchIdBackup;
        this.form.listBrandId = this.listBrandIdBackup;
        this.hideRoleByAdminRoleAccessTypeId(val);
      }
    },
    "form.listBrandId"(val) {
      if (this.$route.params.id == 0) {
        if (val) {
          this.listBrandIdBackup = val;
        }
      }
    },
    "form.listBranchId"(val) {
      if (this.$route.params.id == 0) {
        if (val) {
          this.listBranchIdBackup = val;
        }
      }
    },
  },
  async created() {
    await this.getOptionAccessType();

    await this.getOptionVenue();

    if (this.$route.params.id == 0) {
      this.getOptionPermissionMenu();
    } else {
      this.getPermissionByRoleId();
    }
  },
  computed: {
    childrenPermission() {
      return this.optionsPremission.length > 0
        ? this.optionsPremission.filter(
            (option) => option.parentPermissionId > 0
          )
        : [];
    },

    optionPermissionGroup() {
      let parentPermission = this.optionsPremission.filter(
        (option) =>
          option.parentPermissionId === 0 || !option.parentPermissionId
      );

      if (this.form.permissionId.length > 0) {
        const filterChildren = this.childrenPermission.filter((option) =>
          this.form.permissionId.includes(option.parentPermissionId)
        );
        let result = [];
        parentPermission.forEach((parent) => {
          result.push(parent);
          const relatedChildren = filterChildren.filter(
            (children) => parent.value === children.parentPermissionId
          );

          result.push(...relatedChildren);
        });

        return result;
      } else {
        return parentPermission;
      }
    },
  },
  methods: {
    async getOptionPermissionMenu() {
      const getData = await this.$services.master.getOptionPermissionMenu(true);

      if (getData.result == 1) {
        this.optionsPremission = getData.detail.map((x) => {
          let disabled = false;

          return {
            text: x.name,
            value: x.id,
            parentPermissionId: x.parentPermissionId,
          };
        });
      }
    },
    async getOptionAccessType() {
      const respone = await this.$services.user.getAccessTypeIdOption();
      if (respone.result === 1) {
        this.optionAccessType = respone.detail;
      }
    },
    async getOptionBrand(keyword) {
      const brandData = await this.$services.master.getOptionBrand(
        keyword,
        false
      );
      if (brandData.result === 1) {
        this.optionBrand = brandData.detail;
      }
    },
    async getOptionVenue(keyword) {
      const venueData = await this.$services.master.getOptionVenue(keyword);
      if (venueData.result === 1) {
        this.optionVenue = venueData.detail;
      }
    },
    async manageAdminRole() {
      this.$v.form.$touch();

      if (this.$v.form.$error) {
        return;
      }

      const payload = {
        ...this.form,
        listBrandId:
          this.form.adminRoleAccessTypeId === 2 ||
          this.form.adminRoleAccessTypeId === 4
            ? this.form.listBrandId
            : [],
        listBranchId:
          this.form.adminRoleAccessTypeId === 3 ||
          this.form.adminRoleAccessTypeId === 4
            ? this.form.listBranchId
            : [],
      };

      this.isLoading = true;

      const manage = await this.$services.user.manageAdminRole(
        this.$route.params.id,
        payload
      );

      if (manage.result == 1) {
        this.$store.dispatch("UPDATE_TEXTMODAL", manage.detail.message);
        this.$router.push("/role");
      }
      this.isLoading = false;
    },

    getPermissionByRoleId() {
      if (this.$route.params.id == 0) {
        return;
      }
      this.isLoadingForm = true;

      this.$services.user
        .getPermissionByRoleId(this.$route.params.id)
        .then(async (getData) => {
          if (getData.result == 1) {
            this.form.name = getData.detail.adminRoleName;
            this.form.permissionId = await getData.detail.permission.map(
              (x) => x.permissionMenuId
            );
            this.form.adminRoleAccessTypeId =
              getData.detail.adminRoleAccessTypeId;

            if (getData.detail.listBrandId.length > 0) {
              this.form.listBrandId = await getData.detail.listBrandId.map(
                (e) => e.id
              );
            }

            if (getData.detail.listBranchId.length > 0) {
              this.form.listBranchId = await getData.detail.listBranchId.map(
                (e) => e.id
              );
            }
          } else {
            this.$router.push("/role");
          }
        })
        .finally(async () => {
          this.listBrandIdBackup = this.form.listBrandId;
          this.listBranchIdBackup = this.form.listBranchId;
          this.isLoadingForm = false;
          await this.getOptionPermissionMenu();
          this.hideRoleByAdminRoleAccessTypeId(this.form.adminRoleAccessTypeId);
        });
    },
    hideRoleByAdminRoleAccessTypeId(val) {
      const roleIndex = this.optionsPremission.findIndex(
        (obj) => obj.value === 6
      );

      if (val > 1) {
        const roleFormIndex = this.form.permissionId.findIndex(
          (obj) => obj === 6
        );
        if (roleFormIndex > -1) this.form.permissionId.splice(roleFormIndex, 1);

        if (roleIndex > -1) this.optionsPremission.splice(roleIndex, 1);
      } else {
        if (roleIndex === -1) this.getOptionPermissionMenu();
      }
    },
    goBackRouter() {
      this.$router.back();
    },
    onChangePermission($bvEvent) {
      const selectedChildren = this.childrenPermission.filter((x) =>
        $bvEvent.includes(x.value)
      );

      const missingParents = selectedChildren.filter(
        (child) => !this.form.permissionId.includes(child.parentPermissionId)
      );

      if (missingParents.length > 0) {
        missingParents.forEach((missingParent) => {
          const index = this.form.permissionId.indexOf(missingParent.value);
          if (index > -1) {
            this.form.permissionId.splice(index, 1);
          }
        });
      }
    },

    handleSearchOptionVenue(val) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.getOptionVenue(val);
      }, 1000);
    },
    handleSearchOptionBrand(val) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.getOptionBrand(val);
      }, 1000);
    },
  },
};
``;
</script>

<style lang="scss" scoped>
.create-admin {
  .text-header {
    color: #092d53;
    font-weight: 600;
    font-size: 20px;
    text-transform: uppercase;
  }
  .header-title {
    padding: 15px;
    background: var(--secondary-color);
    color: var(--font-color);
    .title {
      color: white;
    }
  }
  .body {
    padding: 15px;
    background: white;
  }
  .footer {
    padding: 15px 0px;
    display: flex;
    justify-content: space-between;

    .btn {
      min-width: 100px;
    }
  }
}
</style>
